import type { CSSProperties, ReactNode, ComponentPropsWithoutRef } from "react";
import {
  schema as processScheduleSchema,
  uiSchema as processScheduleUiSchema,
} from "src/domain/production/processInstance/configSchema";
import type { IconName } from "@blueprintjs/icons";
import { UiSchema } from "@rjsf/utils";
import type { ComponentImportRegistry } from "./componentImports";
import { type JSONSchema } from "json-schema-to-ts";
import { Permission } from "domain/application/permissions/Permissions";
import { isPermitted } from "./jm";
import { BlueprintIcons_16Id } from "@blueprintjs/icons/lib/esm/generated/16px/blueprint-icons-16";

export type ComponentName = keyof ComponentImportRegistry;

// We won't require all uiprops to be set either at init or in the state.
// The clients of the props can provide defaults if they don't exist.
export type UIProps = Partial<{
  label: string;
  icon: IconName;

  resizable: boolean;

  dynamicSizing: boolean;

  maximised: boolean;
  minimised: boolean;
  minimisable: boolean;
  maximisable: boolean;

  minWidth: number;
  minHeight: number;
  width: number;
  height: number;
  restoredWidth: number;
  restoredHeight: number;

  xPosition: number;
  yPosition: number;
  xRestoredPosition: number;
  yRestoredPosition: number;
  centered: boolean;

  isActive: boolean;
  zIndex: CSSProperties["zIndex"];
  tools: ReactNode;

  mobileStackIndex: number; // Use if we want to insert the component into a specific place in the stack
}>;

export type ComponentProps<T extends ComponentName> = ComponentPropsWithoutRef<
  ComponentImportRegistry[T]
>;

export type ComponentState<T extends ComponentName> = {
  componentType: T;
  componentProps: ComponentProps<T>;
  uiProps: UIProps;
};

export const isValidComponentType = (
  key: string
): key is keyof ComponentImportRegistry => {
  return Object.prototype.hasOwnProperty.call(componentOptions, key);
};

export type Group = "sales" | "production" | "inventory" | "admin" | "other";

export function isGroup(value: any): value is Group {
  return ["sales", "production", "inventory", "admin", "other"].includes(value);
}

export const componentGroups: Record<
  Group,
  { name: string; icon?: BlueprintIcons_16Id }
> = {
  sales: {
    name: "Sales",
    icon: "dollar",
  },
  production: {
    name: "Production",
  },
  inventory: {
    name: "Inventory",
    icon: "box",
  },
  admin: {
    name: "Administration",
    icon: "cog",
  },
  other: {
    name: "Other",
    icon: "menu",
  },
};

export type ComponentOptionsRegistryEntry = {
  defaultUiOptions: Partial<UIProps>;
  component?: ComponentName;
  defaultComponentProps?: ComponentProps<ComponentName>;
  config?: {
    schema?: JSONSchema;
    uiSchema?: UiSchema;
  };
  requiredPermissions?: Permission[];
  showInLauncher: boolean;
  canOpen?: () => Promise<boolean> | boolean;
  group?: Group;
};

// IMPORTANT: Do not import this anywhere except into ProxyComponent,
// as it introduces circular deps and breaks HMR
export const componentOptions: Record<
  ComponentName,
  ComponentOptionsRegistryEntry
> = {
  JobEditor: {
    defaultUiOptions: {
      label: "Job",
      maximised: true,
    },
    showInLauncher: false,
  },
  LeadEditor: {
    defaultUiOptions: {
      label: "Lead",
      maximised: true,
    },
    showInLauncher: false,
    canOpen: () => true,
  },
  JobWizard: {
    defaultUiOptions: {
      label: "New Job",
      maximised: false,
      width: 700,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  JobPhotoUpload: {
    defaultUiOptions: {
      label: "Job Photo Upload",
      minimisable: false,
      maximisable: false,
      maximised: false,
      width: 500,
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Upload_Request_Url),
  },
  Jobs: {
    defaultUiOptions: {
      label: "Jobs",
      maximised: true,
      icon: "projects",
    },
    canOpen: () => isPermitted(Permission.Query_Jobs),
    showInLauncher: true,
  },
  LineItemEditor: {
    defaultUiOptions: {
      label: "Line Item",
    },
    showInLauncher: false,
  },
  RoleManager: {
    defaultUiOptions: {
      label: "Roles",
      maximised: false,
      icon: "people",
    },
    showInLauncher: true,
    group: "admin",
    canOpen: () => isPermitted(Permission.Query_Roles),
  },
  // MetabaseDashboard: {
  //   component: lazy(
  //     () => import("src/domain/report/metabase/MetabaseDashboard")
  //   ),
  //   defaultUiOptions: {
  //     label: "Dashboard",
  //   },
  //   configSchema: metabaseDashboardConfigSchema,
  // },
  ProductImport: {
    defaultUiOptions: {
      label: "Product Import",
      maximised: true,
    },
    showInLauncher: false,
  },
  ProductManager: {
    defaultUiOptions: {
      label: "Products",
      maximised: true,
    },
    showInLauncher: true,
    group: "inventory",
    //requiredPermissions: [Permission.PRODUCTS_IMPORT],
  },
  QuoteEditor: {
    defaultUiOptions: {
      label: "Quote Editor",
      maximised: true,
    },
    showInLauncher: false,
  },
  Quotes: {
    defaultUiOptions: {
      label: "Quotes",
      maximised: true,
    },
    group: "sales",
    showInLauncher: true,
    canOpen: () => false,
  },
  Branches: {
    defaultUiOptions: {
      label: "Branches",
      icon: "office",
    },
    group: "admin",
    showInLauncher: true,
  },
  // SchemaVersionEditor: {
  //   component: lazy(() => import("src/lib/customForm/SchemaVersionEditor")),
  //   defaultUiOptions: {
  //     label: "Schema Version Editor",
  //     maximised: true,
  //   },
  // },
  // TemplateEditor: {
  //   component: lazy(
  //     () => import("src/lib/customTemplate/CustomTemplateEditor")
  //   ),
  //   defaultUiOptions: {
  //     maximised: true,
  //     label: "Template Editor",
  //   },
  // },
  TimeDashboard: {
    defaultUiOptions: {
      label: "Time Dashboard",
    },
    showInLauncher: true,
  },
  // Timesheet: {
  //   component: lazy(() => import("src/domain/production/timesheet/Timesheet")),
  //   defaultUiOptions: {
  //     label: "Timesheet",
  //   },
  //   configSchema: timesheetSchema,
  // },
  HousingCompanyManager: {
    defaultUiOptions: {
      label: "Housing Companies",
      maximised: true,
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Housing_Companies),
  },
  SupplierManager: {
    defaultUiOptions: {
      label: "Suppliers",
      maximised: true,
    },
    group: "inventory",
    showInLauncher: true,
  },
  AutoScreen: {
    defaultUiOptions: {
      label: "Auto Screen",
      maximised: true,
    },
    showInLauncher: true,
    canOpen: () => false,
  },
  SupplierEditor: {
    defaultUiOptions: {
      label: "Supplier",
      width: 500,
      height: 500,
      resizable: true,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  SupplierWizard: {
    defaultUiOptions: {
      label: "New Supplier",
      width: 800,
      height: 650,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  HousingCompanyEditor: {
    defaultUiOptions: {
      label: "Housing Company",
      width: 400,
      height: 625,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  HousingCompanyWizard: {
    defaultUiOptions: {
      label: "New Housing Company",
      width: 400,
      height: 235,
      minHeight: 0,
      minWidth: 0,
      resizable: false,
      maximisable: false,
      minimisable: false,
    },
    showInLauncher: false,
  },
  PurchaseOrderManager: {
    defaultUiOptions: {
      label: "Purchase Orders",
      maximised: true,
    },
    group: "inventory",
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Purchase_Orders),
  },
  RunningSales: {
    defaultUiOptions: {
      label: "Running Sales",
      maximised: true,
    },
    group: "sales",
    showInLauncher: true,
  },
  UserEditor: {
    defaultUiOptions: {
      label: "User",
      maximised: true,
    },
    showInLauncher: false,
  },
  RoleEditor: {
    defaultUiOptions: {
      label: "Role",
      maximised: false,
    },
    showInLauncher: false,
  },
  ProcessSchedule: {
    defaultUiOptions: {
      label: "Process Schedule",
      maximised: true,
      minWidth: 800,
      minHeight: 600,
    },
    config: {
      schema: processScheduleSchema,
      uiSchema: processScheduleUiSchema,
    },
    showInLauncher: true,
  },
  QuoteRequestEditor: {
    defaultUiOptions: {
      label: "Supplier Quote Requests",
      maximised: true,
    },
    showInLauncher: true,
    group: "sales",
  },
  Leads: {
    defaultUiOptions: {
      label: "Leads",
      maximised: true,
      icon: "inbox",
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Leads, true),
    group: "sales",
  },
  Subscriptions: {
    defaultUiOptions: {
      label: "Subscriptions",
      maximised: true,
      icon: "notifications",
    },
    showInLauncher: true,
    canOpen: () => isPermitted(Permission.Query_Subscriptions),
    group: "admin",
  },
  JobEmailEditor: {
    defaultUiOptions: {
      label: "Email",
      maximised: true,
    },
    showInLauncher: false,
  },
  CustomerQuoteRequests: {
    defaultUiOptions: {
      label: "Customer Quote Requests",
      maximised: true,
    },
    group: "sales",
    showInLauncher: true,
  },
  Users: {
    defaultUiOptions: {
      label: "Users",
      maximised: true,
      icon: "person",
    },
    showInLauncher: true,
    group: "admin",
    canOpen: () => isPermitted(Permission.Query_Users),
  },
  // ProcessList: {
  //   defaultUiOptions: {
  //     label: "Process List",
  //     maximised: true,
  //     minWidth: 800,
  //     minHeight: 600,
  //   },
  //   showInLauncher: true,
  //   config: {
  //     schema: processListSchema,
  //     uiSchema: processListUiSchema,
  //   },
  // },
};

// ConfirmedSalesByUser: lazy(
//   () => import("domain/sales/line_item/ConfirmedSalesByUser")
// ),
// CreatedJobsByUser: lazy(
//   () => import("domain/sales/job/CreatedJobsScheduleByUser")
// ),
// CreatedJobs: lazy(() => import("domain/sales/job/CreatedJobsSchedule")),
// TristoneConfirmations: lazy(
//   () => import("domain/sales/line_item/TristoneConfirmations")
// ),
// Jobs: lazy(() =>
//   import("domain/sales/job/Jobs").then((module) => ({ default: module.Jobs }))
// ),
// CondensedJobs: lazy(() => import("domain/sales/job/CondensedJobs")),
// SalesByDesigner: lazy(() => import("domain/sales/line_item/SalesByDesigner")),
// ConfirmedSalesSchedule: lazy(
//   () => import("domain/sales/line_item/ConfirmedSalesSchedule")
// ),
// BenchtopBrands: lazy(
//   () => import("domain/specifications/benchtop_spec/report/BenchtopBrand")
// ),
// BenchtopColour: lazy(
//   () => import("domain/specifications/benchtop_spec/report/BenchtopColour")
// ),
// BenchtopType: lazy(
//   () => import("domain/specifications/benchtop_spec/report/BenchtopType")
// ),
// BowlReport: lazy(
//   () => import("domain/specifications/bowl_spec/report/BowlReport")
// ),
// WeeklySales: lazy(() => import("domain/hr/designer/report/WeeklySales")),
// DoorBrand: lazy(
//   () => import("domain/specifications/door_spec/report/DoorBrand")
// ),
// DoorColour: lazy(
//   () => import("domain/specifications/door_spec/report/DoorColour")
// ),
// DoorType: lazy(
//   () => import("domain/specifications/door_spec/report/DoorType")
// ),
// JobsIn: lazy(() => import("domain/sales/job/report/JobsIn")),
// KitchenCount: lazy(
//   () => import("domain/sales/line_item/report/KitchenCount")
// ),
// MonthlySales: lazy(
//   () => import("domain/sales/line_item/report/MonthlySales")
// ),
// SpeccedMaterialColour: lazy(
//   () => import("domain/specifications/door_spec/report/SpeccedMaterialColour")
// ),
// TristoneColours: lazy(
//   () => import("domain/specifications/benchtop_spec/report/TristoneColours")
// ),
// WeekOnYear: lazy(() => import("domain/sales/line_item/report/WeekOnYear")),
// YearlySales: lazy(() => import("domain/sales/line_item/report/YearlySales")),

// QuoteTemplates: lazy(
//   () => import("domain/sales/line_item/quote_templates/QuoteTemplates")
// ),
// PriceCategories: lazy(
//   () => import("domain/inventory/price_category/PriceCategories")
// ),
// SpecificationTemplates: lazy(
//   () => import("domain/sales/specification/SpecificationTemplates")
// ),
// DataStores: lazy(() => import("domain/common/data_store/DataStores")),
// SalesDashboard: lazy(() => import("domain/sales/SalesDashboard")),
// OliveriBowlReport: lazy(
//   () => import("domain/specifications/bowl_spec/report/OliveriBowlReport")
// ),
// QuoteRequestManager: lazy(
//   () => import("domain/sales/quote_request/QuoteRequestManager")
// ),
// PurchasingDashboard: lazy(
//   () => import("domain/inventory/PurchasingDashboard")
// ),

// ProductManager: lazy(
//   () => import("domain/inventory/product/productManager/ProductManager")
// ),
// ProductOrderHistory: lazy(
//   () =>
//     import("domain/inventory/purchase_order_line/report/ProductOrderHistory")
// ),
// PurchaseOrderEditor: lazy(
//   () => import("domain/inventory/purchase_order/PurchaseOrderEditor")
// ),
// ProductOrderedReport: lazy(
//   () => import("domain/inventory/product/ProductOrderedReport")
// ),
// StockReport: lazy(() => import("domain/inventory/product/StockReport")),
// SheetsMachined: lazy(
//   () => import("domain/workflow/batch/report/SheetsMachined")
// ),
// ProductionDashboard: lazy(
//   () => import("domain/production/ProductionDashboard")
// ),
// ConfirmedPressedDoors: lazy(
//   () => import("domain/specifications/door_spec/report/ConfirmedPressedDoors")
// ),
// EstimatedDueDates: lazy(
//   () => import("domain/sales/line_item/EstimatedDueDatesSchedule")
// ),
// InstallationDashboard: lazy(
//   () => import("domain/sales/line_item/InstallationDashboard")
// ),
// MaintenanceSchedule: lazy(
//   () => import("domain/maintenance/MaintenanceSchedule")
// ),
// RunningMaintenance: lazy(
//   () => import("domain/maintenance/RunningMaintenance")
// ),
// DeletedMaintenance: lazy(
//   () => import("domain/maintenance/DeletedMaintenance")
// ),
// OutstandingMaintenance: lazy(
//   () => import("domain/maintenance/OutstandingMaintenance")
// ),
// MaintenanceReport: lazy(
//   () => import("domain/maintenance/report/MaintenanceReport")
// ),
// UnscheduledMaintenance: lazy(
//   () => import("domain/maintenance/UnscheduledMaintenance")
// ),
// MaintenanceDashboard: lazy(
//   () => import("domain/maintenance/MaintenanceDashboard")
// ),
// RunningRemedials: lazy(() => import("domain/remedial/RunningRemedials")),
// RemedialSchedule: lazy(() => import("domain/remedial/RemedialSchedule")),
// ProcessTypes: lazy(() => import("domain/workflow/process_type/ProcessTypes")),
// WorkflowTemplateEditor: lazy(
//   () => import("domain/workflow/WorkflowTemplateEditor")
// ),
// ProcessList: lazy(
//   () => import("domain/workflow/process_instance/list/ProcessList")
// ),
// MiscTasks: lazy(() => import("domain/workflow/misc_task/MiscTasks")),
// Portals: lazy(() => import("domain/application/portal/Portals")),
// Shutdown: lazy(() => import("domain/common/shutdown/Shutdown")),
// ManageAppliances: lazy(
//   () => import("domain/specifications/appliance_model/ManageAppliances")
// ),
// ComponentManager: lazy(
//   () => import("domain/application/component/ComponentManager")
// ),
// SupplierPurchaseOrders: lazy(
//   () =>
//     import("domain/inventory/supplier/purchase_orders/SupplierPurchaseOrders")
// ),
// SupplierTransactions: lazy(
//   () => import("domain/inventory/supplier/SupplierTransactions")
// ),
// SupplierSchedule: lazy(
//   () => import("domain/inventory/supplier/SupplierPurchaseOrderSchedule")
// ),
// SupplierQuoteRequests: lazy(
//   () =>
//     import("domain/inventory/supplier/quote_requests/SupplierQuoteRequests")
// ),
// FactoryUsers: lazy(() => import("domain/application/user/FactoryUsers")),
// TristoneSales: lazy(() => import("domain/sales/line_item/TristoneSales")),
// JobPhotoUpload: lazy(
//   () => import("domain/sales/job/photoUpload/JobPhotoUpload")
// ),
// Timesheet: lazy(() => import("src/domain/production/timesheet/Timesheet")),
// TimeDashboard: lazy(
//   () => import("src/domain/workflow/timeDashboard/TimeDashboard")
// ),
