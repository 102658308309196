import openWindowAtom from "app/uiState/openWindowAtom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useSetAtom, useAtomValue, atom, useAtom } from "jotai";
import pageDimensionsAtom from "app/uiState/pageDimensionsAtom";
import { graphql } from "src/gql";
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from "@tanstack/react-query";
import { useFetchJmApiQuery, useJmApiQuery } from "src/jmApiClient";
import { getTenantId } from "app/tenant";
import { useCurrentComponent } from "app/useCurrentComponent";
import axios from "axios";
import pushComponentToStack from "app/uiState/mobile/pushComponentToStack";
import type {
  ComponentName,
  ComponentProps,
  UIProps,
} from "app/componentOptions";
import {
  collection,
  getFirestore,
  onSnapshot,
  doc,
  updateDoc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { app } from "app/Firebase";
import { ZodError, ZodObject, ZodSchema, z } from "zod";
import { Permission } from "domain/application/permissions/Permissions";
import { componentOptions } from "app/componentOptions";
import { queryClient } from "./App";
import { fetchJmApiQuery } from "src/jmApiClient";
import { getAuth } from "firebase/auth";

export { useCurrentComponent };
const firestore = getFirestore(app);
const auth = getAuth(app);

//type WindowModuleId = keyof typeof windowModules;

// Use this when wanting to open a window from an id supplied externally
// eg when loading portal items from the server
// export function isValidWindowModuleId(id: string): id is WindowModuleId {
//   return id in windowModules;
// }

export const useOpenWindow = ({
  positionRelativeToParent = true,
}: {
  positionRelativeToParent?: boolean;
} = {}) => {
  const openWindow = useSetAtom(openWindowAtom);
  // const currentWindow = useCurrentComponent({ suppressErrorOnNoContext: true });

  return useCallback(
    <T extends ComponentName>(
      componentType: T,
      componentProps?: ComponentProps<T>,
      uiProps?: Partial<UIProps>,
      windowId?: string
    ) => {
      if (positionRelativeToParent) {
        // const initialXPosition =
        //   currentWindow.componentState.uiProps.xPosition || 0;
        // const initialYPosition =
        //   currentWindow.componentState.uiProps.yPosition || 0;
        // windowProps = {
        //   header: componentProps?.component?.label,
        //   ...options,
        //   xPosition: initialXPosition + 40,
        //   yPosition: initialYPosition + 40,
        // };
      }
      return openWindow({
        componentType,
        componentProps,
        uiProps: uiProps ?? {},
        windowId,
      });
    },
    [openWindow, positionRelativeToParent]
  );
};

export const useOpenComponent = () => {
  // Default the entire parameter object to an empty object
  const isMobileOrTablet = useIsMobileOrTablet();

  const openWindow = useSetAtom(openWindowAtom);
  const openMobileComponent = useSetAtom(pushComponentToStack);

  return useCallback(
    <T extends ComponentName>(
      componentType: T,
      componentProps: ComponentProps<T>,
      uiProps: Partial<UIProps> = {}
    ) => {
      if (isMobileOrTablet) {
        openMobileComponent({
          componentType,
          componentProps,
          uiProps,
        });
        return;
      }
      openWindow({ componentType, componentProps, uiProps });
    },
    [isMobileOrTablet, openWindow, openMobileComponent]
  );
};

export const useJmWebConfig = () => {
  return useFirestoreDocument(
    "jm-web-config/default",
    z.object({ latestVersion: z.string() })
  );
};

/**
 * @param firestorePathKey
 * @param documentSchema  Schema for an individual document in the collection
 * @param useQueryOptions
 * @returns
 */
export const useFirestoreCollection = <Schema extends ZodObject<any>>(
  firestorePathKey: string,
  documentSchema: Schema,
  useQueryOptions?: Omit<UseQueryOptions<Array<z.infer<Schema>>>, "queryKey">
) => {
  const queryClient = useQueryClient();

  const [ref] = useState(() =>
    collection(firestore, firestorePathKey).withConverter<z.infer<Schema>>({
      toFirestore: (data) => ({ ...data }),
      fromFirestore: (snapshot) => {
        const data = snapshot.data();
        try {
          return documentSchema.parse(data);
        } catch (e) {
          if (e instanceof ZodError) {
            console.error(e.issues);
          }
        }
        throw new Error("Failed to parse document data");
      },
    })
  );

  useEffect(() => {
    return onSnapshot(ref, (querySnapshot) => {
      queryClient.setQueryData(
        [firestorePathKey],
        querySnapshot.docs.map((d) => d.data())
      );
    });
  }, [firestorePathKey, queryClient, ref]);

  return useQuery({
    queryKey: [firestorePathKey],
    queryFn: async () => {
      const querySnapshot = await getDocs(ref);
      const data = querySnapshot.docs.map((doc) => {
        return documentSchema.parse(doc.data());
      });
      return data;
    },
    ...useQueryOptions,
  });
};

// TODO: Combine with useFirestoreCollection?
export const useFirestoreDocument = <T extends ZodSchema>(
  firestorePathKey: string,
  schema: T,
  useQueryOptions?: Omit<
    UseQueryOptions<z.infer<typeof schema>>,
    "queryKey" | "queryFn"
  >
) => {
  const queryClient = useQueryClient();

  const [ref] = useState(() =>
    doc(firestore, firestorePathKey).withConverter<z.infer<typeof schema>>({
      toFirestore: (data) => {
        return schema.parse(data);
      },
      fromFirestore: (snapshot) => {
        const data = snapshot.data();
        return schema.parse(data);
      },
    })
  );

  // Firestore snapshot listener to keep data up-to-date
  useEffect(() => {
    const unsubscribe = onSnapshot(ref, (querySnapshot) => {
      queryClient.setQueryData([firestorePathKey], querySnapshot.data());
    });

    return () => unsubscribe();
  }, [firestorePathKey, queryClient, ref]);

  // Provide a query function for initial data fetch
  return useQuery({
    queryKey: [firestorePathKey],
    queryFn: async () => {
      const snapshot = await getDoc(ref);
      const data = snapshot.data();
      return schema.parse(data);
    },
    ...useQueryOptions,
  });
};

export const useFirestoreDocumentMutation = (firestorePathKey: string) => {
  const [ref] = useState(() => doc(firestore, firestorePathKey));

  return useMutation({
    onMutate: async (data: any) => {
      await updateDoc(ref, data);
    },
  });
};

export const usePageDimensions = () => {
  return useAtomValue(pageDimensionsAtom);
};

export const isPermitted = async (
  permission: Permission,
  conditionallyAllowed: boolean = false // Returns true if the policy is conditional
): Promise<boolean> => {
  const token = await auth.currentUser?.getIdTokenResult();
  if (token?.claims?.role === "admin") {
    return true;
  }

  // TODO: Get this to use the cache properly
  const { currentUser } = await fetchJmApiQuery(
    queryClient,
    GetCurrentUserQueryDocument
  );
  const permissions = currentUser?.permissions;

  if (!permissions) return false;

  const foundPermission = permissions.find(
    (p) => p.permission === permission.toString()
  );
  if (!foundPermission) return false;
  if (foundPermission.policy === "deny") return false;
  if (foundPermission.policy === "allow") return true;

  if (conditionallyAllowed) return true;

  // If we get to this point then the permission is conditional and
  // we need a server trip to evaluate
  const data = await fetchJmApiQuery(
    queryClient,
    isPermittedQueryDocument,
    {
      permission,
    },
    {
      //staleTime: 1000 * 60 * 60, // 60 minutes
    }
  );
  return data.isPermitted;
};

export const initAllowedComponents = async () => {
  const filterResults = await Promise.all(
    Object.entries(componentOptions)
      .filter(([key, cfg]) => cfg.showInLauncher)
      .map(async ([key, cfg]) => {
        if (cfg.canOpen) {
          const result = await cfg.canOpen();
          if (result) {
            return {
              componentId: key,
              ...cfg,
            };
          }
        }
        return false;
      })
  );
  return filterResults.filter((r) => !!r);
};

export const useAllowedComponents = () => {
  const { data } = useQuery({
    queryKey: ["allowedComponents"],
    queryFn: initAllowedComponents,
  });
  return data;
};

export const prefetchAllowedComponents = async () => {
  await queryClient.prefetchQuery({
    queryKey: ["allowedComponents"],
    queryFn: initAllowedComponents,
  });
};

export const GetCurrentUserQueryDocument = graphql(`
  query getCurrentUser {
    currentUser {
      id
      fullName
      email
      isActive
      deleted
      authProviderUid
      permissions {
        permission
        policy
      }
      roles {
        id
        name
        description
      }
      screens {
        id
        name
        screenCfg
      }
    }
  }
`);

export const useCurrentUserData = () => {
  //Note: This gets preloaded in App.tsx, so that there is no delay
  // when opening the start menu and listing the available windows.
  const { data: currentUserData, isLoading } = useJmApiQuery(
    GetCurrentUserQueryDocument,
    {},
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      gcTime: Infinity,
      staleTime: Infinity,
      //keepPreviousData: true,
    }
  );

  const isAdmin = useMemo(() => {
    return currentUserData?.currentUser?.fullName === "System";
  }, [currentUserData?.currentUser?.fullName]);

  // const portalWindows = useMemo(() => {
  //   const components =
  //     (currentUserData?.currentUser?.fullName === "System"
  //       ? allComponents?.components
  //       : currentUserData?.currentUser?.portal?.items.map(
  //           (i) => i.component
  //         )) || [];

  //   const windows: {
  //     [windowId: string]: {
  //       name: string;
  //       initial_maximize: boolean;
  //       icon: string;
  //     };
  //   } = {};

  //   components.forEach((component) => {
  //     const window = component?.windowComponent?.window;

  //     if (window && !windows[window.id]) {
  //       windows[window.id] = {
  //         name: window.name || "Untitled Window",
  //         initial_maximize: window.initialMaximize,
  //         icon: window.icon || "window",
  //       };
  //     }
  //   });
  //   return windows;
  // }, [currentUserData, allComponents?.components]);
  return { currentUserData, isLoading, isAdmin };
};

const isPermittedQueryDocument = graphql(`
  query isPermitted($permission: String!) {
    isPermitted(permission: $permission)
  }
`);

/**
 * We could do this "graphql-first" styles:
 * const result =
 */

export const useIsPermitted = (permission: Permission) => {
  return useJmApiQuery(isPermittedQueryDocument, {
    permission,
  });
};

// export const useHasRole = () => {
//   const { currentUserData } = useCurrentUserData();
//   const currentUser = currentUserData?.currentUser;
//   return useCallback(
//     (role: string) => {
//       const roles = currentUser?.roles || [];
//       return roles.some((r) => r.type === role);
//     },
//     [currentUser]
//   );
// };

export const useCurrentTenant = () => {
  const tenantId = getTenantId();
  if (tenantId === "jmadmin") {
    return {
      tenantId,
      displayName: "Job Manager Admin",
      firebaseTenantId: "jmadmin",
      themeColour: "#000000",
    };
  }
  const { data: axiosReponse } = useQuery({
    queryKey: ["tenant", tenantId],
    queryFn: () => axios.get(`api/tenant/${tenantId}`),
    enabled: tenantId !== "jmadmin",
  });
  let publicTenantAssetsUrl;
  if (window.JM_ENVIRONMENT === "DEVELOPMENT") {
    //publicTenantAssetsUrl = `https://jm-web.dev.local:19199/demo-job-manager.appspot.com/tenants/${tenantId}/public`;
    publicTenantAssetsUrl = `http://localhost:9199/demo-job-manager.appspot.com/tenants/${tenantId}/public`;
  } else if (window.JM_ENVIRONMENT === "STAGING") {
    publicTenantAssetsUrl = `https://storage.googleapis.com/staging-tenant-assets.${tenantId}.jobmgr.net`;
  } else {
    publicTenantAssetsUrl = `https://storage.googleapis.com/tenant-assets.${tenantId}.jobmgr.net`;
  }
  return {
    tenantId,
    publicTenantAssetsUrl,
    displayName: axiosReponse?.data?.displayName,
    firebaseTenantId: axiosReponse?.data?.firebaseTenantId,
    themeColour: axiosReponse?.data?.themeColour,
  };
};

// type GetAtomAtPath = <V extends object, P extends string>(
//   atom: WritableAtom<V, unknown[], unknown>,
//   path: P
// ) => WritableAtom<Get<V, P>, unknown[], SetStateAction<Get<V, P>>>;

// export const getAtomAtPath: GetAtomAtPath = <
//   A extends object,
//   P extends string,
// >(
//   atom: WritableAtom<A, unknown[], unknown>,
//   path: P
// ) => {
//   return atomFamily((path: string) =>
//     focusAtom(atom, (optic) => optic.path(path))
//   )(path) as any;
// };

// From: https://stackoverflow.com/a/11381730
// Note: This will return false for tablets. See above link for a version of this function that returns
function mobileCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    /** @ts-expect-error This is a browser check so we disable typechecking */
    navigator.userAgent || navigator.vendor || window.opera
  );
  return check;
}

export const useIsMobile = () => {
  return useMemo(() => {
    return mobileCheck();
  }, []);
};

function tabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      (/(android|ipad|playbook|silk)/i.test(a) && !/mobile/i.test(a)) || // Check for tablets specifically
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    /** @ts-expect-error we are doing a browser check */
    navigator.userAgent || navigator.vendor || window.opera
  );
  return check;
}

export const useIsTablet = () => {
  return useMemo(() => {
    return tabletCheck();
  }, []);
};

export function mobileAndTabletCheck() {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(
    /** @ts-expect-error we are doing a browser check */
    navigator.userAgent || navigator.vendor || window.opera
  );
  return check;
}

export const useIsMobileOrTablet = () => {
  return useMemo(() => {
    return mobileAndTabletCheck();
  }, []);
};

const signoutAtom = atom(false);

export const useSignout = () => {
  return useAtom(signoutAtom);
};
